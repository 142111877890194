import React, { Component } from 'react';
import './Footer.css';

class Footer extends Component {
   
    render() {
      return (
       <div className="MApp-footer"/>
      );
    }
  }
  export default Footer;