import React, { Component } from 'react';
import './Header.css';

class Header extends Component {
   
    render() {
      return (
       <div className="MApp-header"> Race Marker Map</div>
      );
    }
  }
  export default Header;